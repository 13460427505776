import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "menu", "show", "hide"]

  initialize() {
    console.log("menu stimulus controller is ready!")
  }
  
  trigger() {
    //hide or show menu
    this.menuTarget.classList.toggle("hidden")
    
    //switch button 
    this.showTarget.classList.toggle("hidden")
    this.hideTarget.classList.toggle("hidden")
  }
  
} 