import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  initialize() {
    console.log("carousel is ready!")  
    this.showItem(0)
  }

  next() {
    var count = this.itemTargets.length
    var next_item = this.index + 1
    if (next_item > count-1) {
        this.showItem(0)
    } else {
        this.showItem(this.index + 1)
    }
    
  }

  previous() {
    var count = this.itemTargets.length  
    var previous_item = this.index - 1
    if (previous_item < 0) {
        this.showItem(count-1)
    } else {
        this.showItem(this.index - 1)
    }
  }

  showItem(index) {
    this.index = index
    this.itemTargets.forEach((el, i) => {
      el.classList.toggle("item--current", index == i)
    })
  }
  
}