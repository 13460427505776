// app/javascript/controllers/faq_controller.js 

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "theFirstItemInfo", "theSecondItemInfo", "theThirdItemInfo", "theFourthItemInfo" ]

  hideAll() {
    this.theFirstItemInfoTargets.forEach(el => {
      el.hidden = true
    });
    this.theSecondItemInfoTargets.forEach(el => {
      el.hidden = true
    });
    this.theThirdItemInfoTargets.forEach(el => {
      el.hidden = true
    });
    this.theFourthItemInfoTargets.forEach(el => {
      el.hidden = true
    });
  }

  toggleTheFirstItemInfo() {
    this.hideAll();
    this.theFirstItemInfoTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
  }

  toggleTheSecondItemInfo() {
    this.hideAll();
    this.theSecondItemInfoTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
  }  

  toggleTheThirdItemInfo() {
    this.hideAll();
    this.theThirdItemInfoTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
  }

  toggleTheFourthItemInfo() {
    this.hideAll();
    this.theFourthItemInfoTargets.forEach((el) => {
      el.hidden = !el.hidden
    });
  }
}